<template>
    <v-fade-transition>
        <div class="col-12">
            <v-sheet class="dense-inputs">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex flex-row float-right">
                        <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left filter-width-300"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                        />
                        <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                        <v-btn class="ml-3" @click="clearFilter"><v-icon>refresh</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <ExportTableJson
                            :export-conditions="[]"
                            :export-data="contracts"
                            :export-fields="headers"
                            :export-source="'accounts-receivable-report'"
                            style="padding-top: 0px !important; margin-bottom: 15px !important;"
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading.contracts"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :headers="headers"
                :items-per-page="-1"
                :items="contracts"
                :height="tableHeight"
                :search="searchTerm"
                :sort-by="['CustomerPayment.duedate']"
                class="mt-0 appic-table-light specification-table"
                dense
                disable-pagination
                fixed-header
                hide-default-footer
                multi-sort
                id="accountsReceivableTable"
            >
                <template v-slot:item.Contract.title="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.Contract.title }}</div>
                </template>
                <template v-slot:item.Invoice.date="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Invoice.date) }}</div>
                </template>
                <template v-slot:item.Contract.etddate="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Contract.etddate) }}</div>
                </template>
                <template v-slot:item.Contract.etadate="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Contract.etadate) }}</div>
                </template>
                <template v-slot:item.CustomerPayment.outstanding="{ item }">
                    <div class="text-end">{{ numberFormat(item.CustomerPayment.outstanding, '0,0.00') }}</div>
                </template>
                <template v-slot:item.CustomerPayment.duedate="{item}">
                    <div :class="'text-no-wrap font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')">{{ formatDate(item.CustomerPayment.duedate) }}</div>
                </template>
                <template v-slot:item.CustomerPayment.age1to30="{ item }">
                    <div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age1to30 > 0">{{ numberFormat(item.CustomerPayment.age1to30, '0,0.00') }}</div>
                </template>
                <template v-slot:item.CustomerPayment.age31to60="{ item }">
                    <div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age31to60 > 0">{{ numberFormat(item.CustomerPayment.age31to60, '0,0.00') }}</div>
                </template>
                <template v-slot:item.CustomerPayment.age60beyond="{ item }">
                    <div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.age60beyond > 0">{{ numberFormat(item.CustomerPayment.age60beyond, '0,0.00') }}</div>
                </template>
                <template v-slot:item.CustomerPayment.overdue="{ item }">
                    <div :class="'text-no-wrap text-end font-weight-bold' + (overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')" v-if="item.CustomerPayment.overdue > 0">{{ numberFormat(item.CustomerPayment.overdue, '0,0') }}</div>
                </template>
                <template v-slot:item.CustomerPayment.remarks="{ item }">
                    <div class="d-flex flex-row justify-space-between align-center">
                        <template v-for="remark in item.CustomerPayment.remarks">
                            <div class="width-300-px ml-2">{{ remark.text + '     ' + '(' + formatDate(remark.date) + ')' }}</div>
                        </template>
                        <v-spacer v-if="item.CustomerPayment.remarks.length > 0"/>
                        <template v-if="allowedUserRoles.includes(User__role)">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <div class="no-of-remarks" v-if="item.CustomerPayment.noOfPreviousRemarks > 0" v-on="on">{{ item.CustomerPayment.noOfPreviousRemarks }}</div>
                                </template>
                                <span v-if="item.CustomerPayment.noOfPreviousRemarks > 1">{{ item.CustomerPayment.noOfPreviousRemarks + ' ' + $t('message.previousRemarks') }}</span>
                                <span v-else>{{ item.CustomerPayment.noOfPreviousRemarks + ' ' + $t('message.previousRemark') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openRemarksDialog(item.Invoice.id, item.Contract.title, item.Contract.type)" icon class="text-center" v-on="on" v-if="item.Contract.type == 'WH'">
                                        <v-icon v-if="item.CustomerPayment.remarks.length == 0" class="grey--text text--darken-1">mdi mdi-plus</v-icon>
                                        <v-icon v-else small class="grey--text text--darken-1">edit</v-icon>
                                    </v-btn>
                                    <v-btn @click="openRemarksDialog(item.Contract.id, item.Contract.title, item.Contract.type)" icon class="text-center" v-on="on" v-else>
                                        <v-icon v-if="item.CustomerPayment.remarks.length == 0" class="grey--text text--darken-1">mdi mdi-plus</v-icon>
                                        <v-icon v-else small class="grey--text text--darken-1">edit</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="item.CustomerPayment.remarks.length == 0">{{ $t('message.addRemarks') }}</span>
                                <span v-else>{{ $t('message.updateRemarks') }}</span>
                            </v-tooltip>
                        </template>
                    </div>
                </template>
            </v-data-table>
            <AddAccountReceivableRemark
                :dialog.sync="remarksDialog"
                :contract-id="selectedContractId"
                :contract-no="selectedContractNo"
                :contract-type="selectedContractType"
                @dialog-closed="remarksDialogClosed"
                @update-done="remarksUpdated"
            />
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {formatDate, formatDateShort, log, numberFormat} from "Helpers/helpers";
import {mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const AddAccountReceivableRemark = () => import("Components/Appic/AddAccountReceivableRemark");

export default {
    name: "AccountsReceivableList",
    components: { AddAccountReceivableRemark, ExportTableJson },
    data() {
        return {
            allowedUserRoles: ['ROLE_ADMINISTRATOR','ROLE_SUPERADMIN','ROLE_SALES','ROLE_EXPORT_SALES','ROLE_COUNTRY_MANAGER'],
            contracts: [],
            loading: {
                clear: false,
                contracts: false,
                filterResults: false
            },
            remarksDialog: false,
            searchTerm: null,
            selectedContractNo: null,
            selectedContractId: null,
            selectedContractType: null,
            tableHeight: '500'
        }
    },
    computed: {
        ...mapFields('user',{
            User__role: 'current.User.role',
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.sales'),
                    value: 'Contract.sales',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.type'),
                    value: 'Contract.type',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'Customer.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.invoice'),
                    value: 'Invoice.no',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.date'),
                    value: 'Invoice.date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.terms'),
                    value: 'Contract.type_insurance',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.buyerTerms'),
                    value: 'CustomerPayment.status',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.etd'),
                    value: 'Contract.etddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.eta'),
                    value: 'Contract.etadate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.cur'),
                    value: 'Currency.code',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.balance'),
                    value: 'CustomerPayment.outstanding',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.due'),
                    value: 'CustomerPayment.duedate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: '1-30' + this.$t('message.days'),
                    value: 'CustomerPayment.age1to30',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: '31-60' + this.$t('message.days'),
                    value: 'CustomerPayment.age31to60',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: '>60' + this.$t('message.days'),
                    value: 'CustomerPayment.age60beyond',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: this.$t('message.overdue'),
                    value: 'CustomerPayment.overdue',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 18,
                    text: this.$t('message.remarks'),
                    value: 'CustomerPayment.remarks',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                }
            ]
        }
    },
    methods: {
        clearFilter() {
            this.searchTerm = null
            this.loadReport()
        },
        formatDate,
        formatDateShort,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (200);
        },
        loadReport() {
            return new Promise((resolve, reject) => {
                this.loading.contracts = true
                api
                    .get('/reports/buyer-aging')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.contracts = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.contracts = false
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.contracts = false
                    });
            })
        },
        numberFormat,
        openRemarksDialog(contractId, contractNo, contractType) {
            this.remarksDialog = true
            this.selectedContractNo = contractNo
            this.selectedContractId = contractId
            this.selectedContractType = contractType
        },
        overDue (date) {
            const now = new Date()
            const testDate = new Date(date)
            if(now.getTime() > testDate.getTime()) return true
            return false
        },
        remarksDialogClosed() {
            this.remarksDialog = false;
        },
        remarksUpdated() {
            this.loadReport()
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.loadReport()
    },
    mounted() {
        this.handleResize()
        this.$emit('mounted')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-300 {
    width: 300px !important;
    max-width: 300px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.width-200-px {
    width: 200px !important;
    max-width: 200px !important;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
.no-of-remarks {
    cursor: pointer;
    height: 24px;
    width: 24px;
    min-width: 24px !important;
    border-radius: 50%;
    background-color: lightgray;
    display: inline-flex !important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column !important;
    justify-content: center;
    position: relative;
}
</style>