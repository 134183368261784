<template>
    <div class="d-flex flex-row">
        {{ tabTitle }}
        <template v-if="overdueInvoices > 0">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                        <v-badge
                            v-bind="attrs"
                            :content="overdueInvoices"
                            :value="overdueInvoices"
                            color="red"
                            overlap
                        ></v-badge>
                    </div>
                </template>
                <span>{{ overdueInvoices + " " + $t('message.invoicesOverdue') }}</span>
            </v-tooltip>
        </template>
    </div>
</template>

<script>
import {api} from "Api";
import {log} from "Helpers/helpers";

export default {
    name: "AccountReceivablesReportTab",
    props: ['tabTitle'],
    data() {
        return {
            overdueInvoices: 0
        }
    },
    methods: {
        loadReport() {
            api
                .get('/reports/buyer-aging')
                .then((response) => {
                    if(response.data.status == 'success') {
                        const contracts = response.data.data
                        this.overdueInvoices = 0
                        contracts.forEach(contract => {
                            this.overdueInvoices += contract.CustomerPayment.overdue > 0 ? 1 : 0
                        })
                        // log(this.overdueInvoices )
                    }
                })
                .catch((err) => {
                    log(err)
                });
        },
    },
    created() {
        this.loadReport()
    }
}
</script>

<style scoped>

</style>