<template>
    <v-fade-transition>
        <div class="report-container col-sm-8" cols="12">
            <v-sheet class="dense-inputs">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex flex-row float-right">
                        <v-select
                            :items="filteredOffices"
                            class="filter-width-125"
                            dense
                            hide-details="auto"
                            item-value="Office.country_id"
                            item-text="Office.code"
                            solo
                            v-model="filterValues.country"
                            @change="filterWareHouseSalesBuyers()"
                        />
                        <v-btn class="ml-2" @click="resetFilters" icon><v-icon>refresh</v-icon></v-btn>
                        <v-spacer/>
                        <ExportTableJson
                            :export-conditions="[]"
                            :export-data="{data: wareHouseBuyers, salesYears: salesYears}"
                            :export-fields="headers"
                            :export-source="'wh-credit-limit-summary'"
                            style="padding-top: 0px !important; margin-bottom: 15px !important;"
                        ></ExportTableJson>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :headers="headers"
                :items="wareHouseBuyers"
                :sort-by="headers[headers.length - 5]['value']"
                :height="tableHeight"
                class="appic-table-light specification-table"
                dense
                disable-pagination
                fixed-header
                hide-default-footer
                sort-desc
                ref="wareHouseSalesCreditsTable"
            >
                <template v-slot:item.WareHouseCredit.buyer="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.WareHouseCredit.buyer }}</div>
                </template>
                <template v-slot:item.WareHouseCredit.sales="{item}">
                    <div class="text-no-wrap">{{ item.WareHouseCredit.sales }}</div>
                </template>
                <template v-for="year in salesYears" #[slotName(year)]="{item}">
                    <div class="text-end" :key="'Y_' + year">{{ formatThisNumber(item['WareHouseCredit']['Y' + year + '_salesValue'],'0,0') }}</div>
                </template>
                <template v-for="year in salesYears" #[slotNameUsd(year)]="{item}">
                    <div class="text-end" :key="'Y_' + year">{{ formatThisNumber(item['WareHouseCredit']['Y' + year + '_salesValueUsd'],'0,0') }}</div>
                </template>
                <template v-slot:item.WareHouseCredit.creditAmount="{item}">
                    <div class="text-end">{{ formatThisNumber(item.WareHouseCredit.creditAmount,'0,0') }}</div>
                </template>
                <template v-slot:item.WareHouseCredit.creditAmountUsd="{item}">
                    <div class="text-end">{{ formatThisNumber(item.WareHouseCredit.creditAmountUsd,'0,0') }}</div>
                </template>
            </v-data-table>
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {log, numberFormat} from "Helpers/helpers";
import {mapGetters, mapActions} from "vuex";
const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "WareHouseSalesCredit",
    components: {ExportTableJson},
    data(){
        return {
            filterItems: {
                countries: [],
                salesColleagues: []
            },
            filterValues: {
                country: 1,
                salesColleague: null
            },
            loading: false,
            localCurrency: 'MYR',
            // salesYears: [2022,2023],
            tableHeight: '500',
            wareHouseBuyers: []
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'offices'
        ]),
        filteredOffices () {
            return this.offices.filter( o => o.Office.has_warehouse === true)
        },
        headers(){
            let headers = [
                {
                    id: 1,
                    text: this.$t('message.buyer'),
                    value: 'WareHouseCredit.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.sales'),
                    value: 'WareHouseCredit.sales',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                }
            ]
            let i = 3
            this.salesYears.forEach(y => {
                headers.push({
                    id: i,
                    text: this.localCurrency,
                    value: 'WareHouseCredit.Y' + y + '_salesValue',
                    class: 'text-end light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                })
                i++
                headers.push({
                    id: i,
                    text: 'USD',
                    value: 'WareHouseCredit.Y' + y + '_salesValueUsd',
                    class: 'text-end light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                })
                i++
            })
            headers.push({
                id: i,
                text: this.localCurrency,
                value: 'WareHouseCredit.creditAmount',
                class: 'text-end light-green lighten-3 pa-1',
                sortable: true,
                searchable: true
            })
            i++
            headers.push({
                id: i,
                text: 'USD',
                value: 'WareHouseCredit.creditAmountUsd',
                class: 'text-end light-green lighten-3 pa-1',
                sortable: true,
                searchable: true
            })
            i++
            headers.push({
                id: i,
                text: this.$t('message.onInvoice') ,
                value: 'WareHouseCredit.creditTerm',
                class: 'light-green lighten-3 pa-1',
                sortable: true,
                searchable: true
            })
            return headers
        },
        salesYears() {
            const currentMonth = new Date().getMonth()
            const currentYear = new Date().getFullYear()
            const previousYear = currentYear - 1
            const twoYearsAgo = currentYear - 2
            if(currentMonth <= 2){
                return [twoYearsAgo, previousYear, currentYear]
            } else {
                return [previousYear, currentYear]
            }
        }
    },
    methods: {
        ...mapActions('currency',{
            getAllCurrencies: 'getAllCurrencies'
        }),
        filterWareHouseSalesBuyers() {
            this.setLocalCurrency()
                .then(() => {
                    this.loading = true
                    this.loadReport()
                        .then((data) => {
                            this.wareHouseBuyers = data
                            this.loading = false
                        })
                        .catch((error) => {
                            this.$toast.error( error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading = false
                        })
                })
                .catch((error) => {
                    this.$toast.error( error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading = false
                })
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (200);
        },
        loadReport() {
            let conditions = []
            if(this.filterValues.country != null){
                conditions.push({
                    field: 'customers.country_id',
                    value: this.filterValues.country
                })
            }
            if(this.filterValues.salesColleague != null){
                conditions.push({
                    field: 'customers.salescontact_id',
                    value: this.filterValues.salesColleague
                })
            }
            return new Promise((resolve, reject) => {
                api
                    .get('/reports/warehouse-sales-credit', {
                        params: {
                            conditions: conditions,
                            salesYears: this.salesYears
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            resolve(response.data.data)
                        } else {
                            reject(response.data.status)
                        }
                    })
                    .catch((error) => {
                        reject('error')
                    });
            })
        },
        resetFilters() {
            this.filterValues.country = 1
            this.filterValues.salesColleague = null
            this.setLocalCurrency()
                .then(() => {
                    this.loading = true
                    this.loadReport()
                        .then((data) => {
                            this.wareHouseBuyers = data
                            this.loading = false
                        })
                        .catch((error) => {
                            this.$toast.error( error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading = false
                        })
                })
                .catch((error) => {
                    this.$toast.error( error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading = false
                })
        },
        setLocalCurrency() {
            return new Promise((resolve, reject)=> {
                try {
                    const office = this.offices.find(o => {
                        return o.Office.country_id == this.filterValues.country && o.Office.has_warehouse === true
                    })
                    if(office){
                        const currencyId = office.Office.currency_id
                        if(currencyId){
                            const currency = this.allCurrencies.find(c => c.Currency.id == currencyId)
                            if(currency) this.localCurrency =  currency.Currency.code
                        }
                    }
                    resolve('done')
                } catch(error) {
                    reject(error)
                }
            })
        },
        slotName(year) {
            return 'item.WareHouseCredit.Y' + year + '_salesValue'
        },
        slotNameUsd(year) {
            return 'item.WareHouseCredit.Y' + year + '_salesValueUsd'
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize)
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        this.loading = true
        if (this.User) {
            switch (this.User.country_id) {
                case 4:
                    this.filterValues.country = 4
                    break
                case 1:
                default:
                    this.filterValues.country = 1
                    break
            }
        } else {
            this.filterValues.country = 1
        }
        this.setLocalCurrency()
        this.loadReport()
            .then((data) => {
                this.wareHouseBuyers = data
                this.loading = false
            })
            .catch((error) => {
                this.$toast.error( error,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading = false
            })
    },
    mounted() {
        this.$nextTick(() => {
            const targetDataTable = this.$refs.wareHouseSalesCreditsTable.$el
            const tableHeader = targetDataTable.getElementsByTagName('thead')[0]
            let newRow = document.createElement('tr')
            newRow.insertCell(0).outerHTML = '<th class="text-start light-green lighten-3 pa-1 sortable"></th>'
            newRow.insertCell(1).outerHTML = '<th class="text-start light-green lighten-3 pa-1 sortable"></th>'
            let i = 2
            this.salesYears.forEach(year => {
                newRow.insertCell(i).outerHTML = '<th class="text-center light-green lighten-3 pa-1 sortable" colspan="2">' + year + '</th>'
                i++
            })
            newRow.insertCell(i).outerHTML = '<th class="text-center light-green lighten-3 pa-1 sortable" colspan="2">' + this.$t('message.currentApprovedCredit') + '</th>'
            i++
            newRow.insertCell(i).outerHTML = '<th class="text-start light-green lighten-3 pa-1 sortable"></th>'
            tableHeader.prepend(newRow)
            this.handleResize()
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-125 {
    max-width: 125px !important;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
</style>