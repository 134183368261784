<template>
    <div class="col-12 col-md-9">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center mr-5 pt-3 flex-md-wrap">
                    <v-select
                        :placeholder="$t('message.monthYear')"
                        :items="filterOptions.ReportDate"
                        autocomplete="password"
                        class="filter-width-175"
                        dense
                        hide-details="auto"
                        solo
                        @change="filterList('ReportDate',$event)"
                        v-model="filterValues.ReportDate"
                    />
                    <v-autocomplete
                        :placeholder="$t('message.buyer')"
                        :items="filterOptions.Buyer"
                        autocomplete="password"
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        @change="filterList('Buyer',$event)"
                        v-model="filterValues.Buyer"
                    />
                    <v-autocomplete
                        :placeholder="$t('message.salesColleague')"
                        :items="filterOptions.SalesColleague"
                        autocomplete="password"s
                        class="filter-width-175 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        @change="filterList('SalesColleague',$event)"
                        v-model="filterValues.SalesColleague"
                    />
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="filteredBuyerLog"
                    :export-fields="headers"
                    :export-source="'ots-access-log'"
                    class="ml-3"
                />
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.buyerLog"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="filteredBuyerLog"
            :items-per-page="-1"
            :height="tableHeight"
            :sort-by="['OtsLog.DaysAccessed']"
            :sort-desc="[true]"
            calculate-widths
            class="mt-3 appic-table-light specification-table px-0"
            dense
            disable-pagination
            fixed-header
            hide-default-footer
            multi-sort
            id="buyerLogTable"
        >
            <template v-slot:item.OtsLog.DaysAccessed="{ item }">
                <div v-if="item.OtsLog.DaysAccessed > 0">{{ item.OtsLog.DaysAccessed }}</div>
                <div class="red--text darken-1" v-else>{{ $t('message.neverAccessed') }}</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {api} from "Api";
import {mapFields} from "vuex-map-fields";
const ExportTableJson = () => import("Components/Appic/ExportTableJson")

export default {
    name: "AppicBuyerAccessLog",
    components: { ExportTableJson },
    data() {
        return {
            buyerLog: [],
            exportConditions: null,
            filterOptions: {
                ReportDate: [],
                DaysAccessed: [],
                Buyer: [],
                BuyerContact: [],
                AccessLocation: [],
                SalesColleague: []
            },
            filteredBuyerLog: [],
            filterValues: {
                Buyer: null,
                ReportDate: 'This Month',
                SalesColleague: null
            },
            loading: {
                buyerLog: false,
                clear: false
            },
            monthYears: [],
            tableHeight: '300'
        }
    },
    computed: {
        ...mapFields('user', {
            User__role: 'current.User.role',
        }),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.monthYear'),
                    value: 'OtsLog.ReportDate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.daysAccessed'),
                    value: 'OtsLog.DaysAccessed',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.buyerActiveContract'),
                    value: 'OtsLog.Buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.accessUser'),
                    value: 'OtsLog.BuyerContact',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.accessLocation'),
                    value: 'OtsLog.AccessLocation',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.salesColleague'),
                    value: 'OtsLog.SalesColleague',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                }
            ]
        }
    },
    methods: {
        clearFilters(){
            this.loading.clear = true
            this.filterValues.Buyer = null
            this.filterValues.ReportDate = 'This Month'
            this.filterValues.SalesColleague = null
            this.fetchBuyerLog()
        },
        clearFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            this.filterOptions[b] = []
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (225);
        },
        fetch() {
            return new Promise((resolve, reject) => {
                try {
                    this.loadBuyerLog()
                        .then(() => {
                            this.loadFilterOptions()
                                .then(() => {
                                    resolve('done')
                                })
                                .catch((e) => {
                                    reject(e)
                                })
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } catch(e) {
                    reject(e)
                }
            })
        },
        fetchBuyerLog(){
            this.loading.buyerLog = true
            this.clearFilterOptions()
                .then(() => {
                    this.fetch()
                        .then(() => {
                            this.filterList()
                                .then(() => {
                                    this.loading.buyerLog = false
                                    this.loading.clear = false
                                })
                                .catch((error) => {
                                    this.loading.buyerLog = false
                                    this.loading.clear = false
                                    this.$toast.error(error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.loading.buyerLog = false
                            this.loading.clear = false
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
                .catch((error) => {
                    this.loading.buyerLog = false
                    this.loading.clear = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        filterList(column = null, value = []) {
            return new Promise((resolve, reject) => {
                try {
                    this.filterValues[column] = value
                    let filterFunctions = []
                    for (const field in this.filterValues) {
                        if (field != 'warehouse') {
                            if (this.filterValues[field] != null && this.filterValues[field].length > 0) {
                                filterFunctions.push((item) => {
                                    return this.filterValues[field].includes(item['OtsLog'][field])
                                })
                            }
                        }
                    }
                    if (filterFunctions.length > 0) {
                        this.filteredBuyerLog = this.buyerLog.filter(item => filterFunctions.every(f => f(item)))
                    } else {
                        this.filteredBuyerLog = this.buyerLog
                    }
                    resolve('done')
                } catch (e) {
                    reject(e)
                }
            })
        },
        loadBuyerLog() {
            return new Promise((resolve, reject) => {
                api
                    .get('/reports/buyers/appic-ots')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.buyerLog = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },
        loadFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            let options = this.buyerLog.map(item => item[a][b])
                            if (options.length > 0) {
                                options.filter((item, index) => options.indexOf(item) === index)
                                options.filter(Boolean)
                                options.sort()
                                this.filterOptions[b] = options
                            }
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.fetchBuyerLog()
    },
    mounted() {
        this.handleResize()
        this.$emit('mounted')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.height-10px {
    height: 10px;
}
</style>