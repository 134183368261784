var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{staticClass:"col-12"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left filter-width-300",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.clearFilter}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-spacer'),_c('ExportTableJson',{staticStyle:{"padding-top":"0px !important","margin-bottom":"15px !important"},attrs:{"export-conditions":[],"export-data":_vm.contracts,"export-fields":_vm.headers,"export-source":'accounts-receivable-report'}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.contracts,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.contracts,"height":_vm.tableHeight,"search":_vm.searchTerm,"sort-by":['CustomerPayment.duedate'],"dense":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","multi-sort":"","id":"accountsReceivableTable"},scopedSlots:_vm._u([{key:"item.Contract.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap font-weight-bold"},[_vm._v(_vm._s(item.Contract.title))])]}},{key:"item.Invoice.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Invoice.date)))])]}},{key:"item.Contract.etddate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etddate)))])]}},{key:"item.Contract.etadate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etadate)))])]}},{key:"item.CustomerPayment.outstanding",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.outstanding, '0,0.00')))])]}},{key:"item.CustomerPayment.duedate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:'text-no-wrap font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.formatDate(item.CustomerPayment.duedate)))])]}},{key:"item.CustomerPayment.age1to30",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age1to30 > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age1to30, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.age31to60",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age31to60 > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age31to60, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.age60beyond",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.age60beyond > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.age60beyond, '0,0.00')))]):_vm._e()]}},{key:"item.CustomerPayment.overdue",fn:function(ref){
var item = ref.item;
return [(item.CustomerPayment.overdue > 0)?_c('div',{class:'text-no-wrap text-end font-weight-bold' + (_vm.overDue(item.CustomerPayment.duedate) ? ' red--text text--darken-1' : '')},[_vm._v(_vm._s(_vm.numberFormat(item.CustomerPayment.overdue, '0,0')))]):_vm._e()]}},{key:"item.CustomerPayment.remarks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_vm._l((item.CustomerPayment.remarks),function(remark){return [_c('div',{staticClass:"width-300-px ml-2"},[_vm._v(_vm._s(remark.text + ' ' + '(' + _vm.formatDate(remark.date) + ')'))])]}),(item.CustomerPayment.remarks.length > 0)?_c('v-spacer'):_vm._e(),(_vm.allowedUserRoles.includes(_vm.User__role))?[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.CustomerPayment.noOfPreviousRemarks > 0)?_c('div',_vm._g({staticClass:"no-of-remarks"},on),[_vm._v(_vm._s(item.CustomerPayment.noOfPreviousRemarks))]):_vm._e()]}}],null,true)},[(item.CustomerPayment.noOfPreviousRemarks > 1)?_c('span',[_vm._v(_vm._s(item.CustomerPayment.noOfPreviousRemarks + ' ' + _vm.$t('message.previousRemarks')))]):_c('span',[_vm._v(_vm._s(item.CustomerPayment.noOfPreviousRemarks + ' ' + _vm.$t('message.previousRemark')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.Contract.type == 'WH')?_c('v-btn',_vm._g({staticClass:"text-center",attrs:{"icon":""},on:{"click":function($event){return _vm.openRemarksDialog(item.Invoice.id, item.Contract.title, item.Contract.type)}}},on),[(item.CustomerPayment.remarks.length == 0)?_c('v-icon',{staticClass:"grey--text text--darken-1"},[_vm._v("mdi mdi-plus")]):_c('v-icon',{staticClass:"grey--text text--darken-1",attrs:{"small":""}},[_vm._v("edit")])],1):_c('v-btn',_vm._g({staticClass:"text-center",attrs:{"icon":""},on:{"click":function($event){return _vm.openRemarksDialog(item.Contract.id, item.Contract.title, item.Contract.type)}}},on),[(item.CustomerPayment.remarks.length == 0)?_c('v-icon',{staticClass:"grey--text text--darken-1"},[_vm._v("mdi mdi-plus")]):_c('v-icon',{staticClass:"grey--text text--darken-1",attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[(item.CustomerPayment.remarks.length == 0)?_c('span',[_vm._v(_vm._s(_vm.$t('message.addRemarks')))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.updateRemarks')))])])]:_vm._e()],2)]}}])}),_c('AddAccountReceivableRemark',{attrs:{"dialog":_vm.remarksDialog,"contract-id":_vm.selectedContractId,"contract-no":_vm.selectedContractNo,"contract-type":_vm.selectedContractType},on:{"update:dialog":function($event){_vm.remarksDialog=$event},"dialog-closed":_vm.remarksDialogClosed,"update-done":_vm.remarksUpdated}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }