var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{staticClass:"report-container col-sm-8",attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12"}},[_c('v-select',{staticClass:"filter-width-125",attrs:{"items":_vm.filteredOffices,"dense":"","hide-details":"auto","item-value":"Office.country_id","item-text":"Office.code","solo":""},on:{"change":function($event){return _vm.filterWareHouseSalesBuyers()}},model:{value:(_vm.filterValues.country),callback:function ($$v) {_vm.$set(_vm.filterValues, "country", $$v)},expression:"filterValues.country"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.resetFilters}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-spacer'),_c('ExportTableJson',{staticStyle:{"padding-top":"0px !important","margin-bottom":"15px !important"},attrs:{"export-conditions":[],"export-data":{data: _vm.wareHouseBuyers, salesYears: _vm.salesYears},"export-fields":_vm.headers,"export-source":'wh-credit-limit-summary'}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{ref:"wareHouseSalesCreditsTable",staticClass:"appic-table-light specification-table",attrs:{"headers":_vm.headers,"items":_vm.wareHouseBuyers,"sort-by":_vm.headers[_vm.headers.length - 5]['value'],"height":_vm.tableHeight,"dense":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","sort-desc":""},scopedSlots:_vm._u([{key:"item.WareHouseCredit.buyer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap font-weight-bold"},[_vm._v(_vm._s(item.WareHouseCredit.buyer))])]}},{key:"item.WareHouseCredit.sales",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.WareHouseCredit.sales))])]}},_vm._l((_vm.salesYears),function(year){return {key:_vm.slotName(year),fn:function(ref){
var item = ref.item;
return [_c('div',{key:'Y_' + year,staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThisNumber(item['WareHouseCredit']['Y' + year + '_salesValue'],'0,0')))])]}}}),_vm._l((_vm.salesYears),function(year){return {key:_vm.slotNameUsd(year),fn:function(ref){
var item = ref.item;
return [_c('div',{key:'Y_' + year,staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThisNumber(item['WareHouseCredit']['Y' + year + '_salesValueUsd'],'0,0')))])]}}}),{key:"item.WareHouseCredit.creditAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThisNumber(item.WareHouseCredit.creditAmount,'0,0')))])]}},{key:"item.WareHouseCredit.creditAmountUsd",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThisNumber(item.WareHouseCredit.creditAmountUsd,'0,0')))])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }